import { useCallback } from "react"
import { useEffect } from "react"
import { toLocale } from "../utils"
import { useState } from "react"
import { fetchDayData } from "../state/stake"
import { useMemo } from "react"
import { EXPLORER_LINKS } from "../config"
import { formatEther } from "ethers/lib/utils"
import moment from "moment/moment"
import { useHFGPrice } from "./useHFGPrice"

export const useDayData = () => {
    const [items, setItems] = useState([])

    const { price } = useHFGPrice()

    const fetch = useCallback(async () => {
        let twentyNineCount = 0
        let twentyNineCountTemp = 0
        try {
            let data = await fetchDayData()
            console.debug("data", data)

            data.forEach((item) => {
                let tempCount = parseInt(moment(item.blockTimestamp * 1000).format('DD'))
                if (tempCount == 21) {
                    twentyNineCountTemp += 1
                }
            })

            console.debug(twentyNineCountTemp)


            const formattedData = data.map((item, index) => {
                let dateDisplay = moment(item.blockTimestamp * 1000).format('DD MMM')
                let tempCount = parseInt(moment(item.blockTimestamp * 1000).format('DD'))
                if (twentyNineCountTemp === 3) {
                    if (tempCount == 21 && twentyNineCount == 0) {
                        twentyNineCount += 1
                        dateDisplay = "20 APR"
                    } else if (tempCount == 21 && twentyNineCount == 1) {
                        twentyNineCount += 1
                        dateDisplay = "19 APR"
                    } else if (tempCount == 21 && twentyNineCount == 2) {
                        twentyNineCount += 1
                        dateDisplay = "18 APR"
                    }
                }
                if (twentyNineCountTemp === 4) {
                    if (tempCount == 21 && twentyNineCount == 0) {
                        dateDisplay = "21 APR"
                    }
                    if (tempCount == 21 && twentyNineCount == 1) {
                        dateDisplay = "20 APR"
                    } else if (tempCount == 21 && twentyNineCount == 2) {
                        dateDisplay = "19 APR"
                    } else if (tempCount == 21 && twentyNineCount == 3) {
                        dateDisplay = "18 APR"
                    }
                    twentyNineCount += 1
                }
                return (
                    <div className="newsbox">
                        <div className="roundwrap">
                            <div className="roundbg text-center" style={{ height: 'auto' }}>
                                <h2>
                                    {dateDisplay}
                                </h2>
                            </div>
                        </div>
                        <div className="infolabel">
                            <div className="label1">
                                <span>Total Collected</span>
                                <span>{toLocale(formatEther(item.amount), 2)} HFG</span>
                            </div>
                            <div className="label1">
                                <span>Total USD</span>
                                <span>${toLocale((formatEther(item.amount)) * price, 2)}</span>
                            </div>
                            <div className="label1">
                                <span>Total Distributed</span>
                                <span>{toLocale(formatEther(item.amount) / 100, 2)} HFG</span>
                            </div>
                            <div className="label1">
                                <span>Distributed USD</span>
                                <span>${toLocale((formatEther(item.amount) / 100) * price, 2)} HFG</span>
                            </div>
                            <div className="label1">
                                <span>Shares Recorded</span>
                                <span>{toLocale(item.shares)}</span>
                            </div>
                            <div className="label1">
                                <span>Per Share</span>
                                <span>{toLocale(formatEther(item.perShare), 6)}</span>
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <a className="viewbtn mx-2" href={`${EXPLORER_LINKS}/tx/${item.transactionHash}`} target="_blank" rel="noreferrer">
                                    View on Explorer
                                </a>
                            </div>
                        </div>
                    </div>
                )
            })
            setItems(formattedData)
        } catch (err) {
            // console.debug(err)
        }
    }, [setItems, price])

    useEffect(() => {
        const inter = setInterval(() => {
            fetch()
        }, 10000)
        return () => clearInterval(inter)
    }, [fetch])

    useEffect(() => {
        fetch()
    }
        , [fetch])

    return useMemo(() => items, [items])
}