import axios from "axios";
import { stakeURL } from "../config";

export const fetchStakeNFTs = (account) => {
    return new Promise(async (res, rej) => {
        try {
            const options = {
                method: 'POST',
                url: stakeURL,
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    query: `{
                        user(id:"${account}") {
                          nftStaked {
                            id
                            nft
                            tokenId
                            shares
                            idx
                            claimCount
                          }
                          claims {
                            id
                            amount
                            blockTimestamp
                          }
                        }
                      }`
                }
            };

            const response = await axios.request(options);
            const resData = response.data;
            if (resData.data.user) {
                if (resData.data.user.nftStaked) {
                    res({
                        nfts: resData.data.user.nftStaked,
                        claims: resData.data.user.claims
                    })
                } else {
                    res({
                        nfts: [],
                        claims: []
                    })
                }
            } else {
                res({
                    nfts: [],
                    claims: []
                })
            }
        } catch (err) {
            // console.debug(":err", err)
            res({
                nfts: [],
                claims: []
            })
        }
    })
}

export const fetchClaims = (account, idx) => {
    return new Promise(async (res, rej) => {
        try {
            const options = {
                method: 'POST',
                url: stakeURL,
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    query: `{
                        rewardClaims(where:{idx:${idx},user:"${account}"}, orderBy:blockTimestamp, orderDirection:desc) {
                          id
                          idx
                          amount
                          blockTimestamp
                          transactionHash
                        }
                      }`
                }
            };

            const response = await axios.request(options);
            const resData = response.data;
            if (resData.data.rewardClaims) {
                if (resData.data.rewardClaims) {
                    res(resData.data.rewardClaims)
                } else {
                    res([])
                }
            } else {
                res([])
            }
        } catch (err) {
            // console.debug(":err", err)
            res([])
        }
    })
}