import { useCallback, useEffect, useState } from "react";
import { useClaim } from "../state/account/hooks";
import { nftTitles } from "../config/nfts";
import { toLocale } from "../utils";
import { useNFTInfo } from "../hooks/useNFT";
import { feeStructure } from "../config";
import { CCol, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";
import { ClaimCounts } from "./ClaimCounts";

export const StakeNFTCard = ({ nft, active = false }) => {

    const [isInTrn, setTransaction] = useState(false);
    const { claim } = useClaim()

    const [nftName, setName] = useState("")
    const [nftGif, setGif] = useState(null)

    const [visible, setVisible] = useState(false)
    const [open, setOpen] = useState(false)

    const { dividendGenerated, totalClaimed, claimCount } = useNFTInfo(nft.idx)

    useEffect(() => {
        const nftInfo = nftTitles.find((nftI) => nftI.address.toLowerCase() === nft.nft.toLowerCase())
        if (nftInfo) {
            setName(nftInfo.value)
            setGif(nftInfo.key)
        }
    }, [nft])

    const claimBtn = useCallback(async () => {
        // setOpen(true)
        setTransaction(true)
        try {
            await claim(nft.idx)
        } catch (err) {

        }
        setTransaction(false)
    }, [claim, setTransaction, nft])

    return (
        <>{(active && parseInt(claimCount) !== 24) ? <CCol xs="12" sm="6" lg="4" xl="3">
            <div className="balancewrap">
                <div className=''>
                    <div className="gifbox">
                        <img src={nftGif} alt='nft-hfg' />
                    </div>
                    <div className='nftTitle'>
                        <h2 className="nftsubtitle">{nftName} <span>(#{nft.tokenId})</span></h2>
                    </div>
                </div>
                <div className='balancelist'>Shares <span>{toLocale(nft.shares)}</span></div>
                <div className='balancelist'>Total Claimed <span>{toLocale(totalClaimed, 6)} HFG</span></div>
                <div className='balancelist'>Claim Count <span>{claimCount} / 24</span></div>
                {parseInt(claimCount) > 0 && <div className='balancelist'>Claims <span>
                    <button className='stakebtn mx-2 mt-2 mb-2' onClick={() => setVisible(true)}>
                        View
                    </button>
                </span></div>}
                {parseInt(claimCount) !== 24 && <div className='balancelist'>Claimable <span>{toLocale(dividendGenerated, 6)} HFG</span></div>}
                {parseInt(claimCount) !== 24 && <div className='balancelist'>Fees ({feeStructure[claimCount]}%) <span>{toLocale(dividendGenerated * feeStructure[claimCount] / 100)} HFG</span></div>}

                {
                    (parseInt(nft.shares) !== 0) && (parseInt(claimCount) !== 24) &&
                    <div className='balancelist d-flex justify-content-center'>
                        {
                            (parseInt(nft.shares) !== 0 && parseFloat(dividendGenerated) > 0) &&

                            <button className='stakebtn mx-2 mt-2 mb-2' disabled={isInTrn} onClick={claimBtn}>
                                {isInTrn ? 'Claiming...' : 'Claim Dividends'}
                            </button>

                        }
                        {
                            (parseInt(nft.shares) !== 0 && parseFloat(dividendGenerated) === 0) &&

                            <button className='stakebtn mx-2 mt-2 mb-2'>Wait for allocation</button>

                        }
                        {
                            (parseInt(nft.shares) === 0) &&
                            "Status"
                        }
                        {/* <span>
                        <button className='stakebtn mx-2 mt-2' disabled={isInTrn} onClick={claimBtn}>
                            Unstake
                        </button>
                    </span> */}
                    </div>
                }

                {
                    (parseInt(claimCount) === 24) &&
                    <button className='stakebtn mx-2 mt-2 mb-2' disabled={true}>
                        All Claimed
                    </button>
                }
            </div>
        </CCol > :
            <CCol xs="12" sm="6" lg="4" xl="3">
                <div className="balancewrap">
                    <div className=''>
                        <div className="gifbox" style={{ background: 'rgba(249, 1, 1, 0.42)' }}>
                            <img src={nftGif} alt='nft-hfg' />
                        </div>
                        <div className='nftTitle'>
                            <h2 className="nftsubtitle">{nftName} <span>(#{nft.tokenId})</span></h2>
                        </div>
                    </div>
                    <div className='balancelist'>Shares <span>{toLocale(nft.shares)}</span></div>
                    <div className='balancelist'>Total Claimed <span>{toLocale(totalClaimed, 6)} HFG</span></div>
                    <div className='balancelist'>Claim Count <span>{claimCount} / 24</span></div>
                    {parseInt(claimCount) > 0 && <div className='balancelist'>Claims <span>
                        <button className='stakebtn mx-2 mt-2 mb-2' onClick={() => setVisible(true)}>
                            View
                        </button>
                    </span></div>}
                    {
                        (parseInt(claimCount) === 24) &&
                        <button className='stakebtn mx-2 mt-2 mb-2' disabled={true}>
                            Inactive
                        </button>
                    }
                </div>
            </CCol >
        }
            <CModal alignment="center" visible={visible} onClose={() => setVisible(false)} className="connectWalletToggle">
                <CModalHeader>
                    <CModalTitle>Claim History</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <ClaimCounts idx={nft.idx} />
                </CModalBody>
            </CModal>

            <CModal alignment="center" visible={open} onClose={() => setOpen(false)} className="connectWalletToggle">
                <CModalHeader>
                    <CModalTitle>Hashfair News!</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <img src="/images/banner.jpg" />
                </CModalBody>
            </CModal>
        </>
    )
}