import React, { useCallback } from "react";
import { CContainer, CButton, CRow, CCol } from "@coreui/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registerToken, setupNetwork } from "../utils/setupNetwork";
import useActiveWeb3React from "../hooks/useWeb3";
import LazyLoad from "react-lazyload";
import { hfgContract } from "../config";
import { Statistics } from "./Statistics";
import { Balance } from "./Balance";
import nftLvl7 from "../Assets/images/nfts/Joker.json";
import Lottie from "react-lottie-player";
import YourNFT from "./YourNFT";
import News from "./News";
import Countdown from "react-countdown";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <strong>staking live now</strong>;
  } else {
    return <strong>{`${days}d ${hours}h ${minutes}m ${seconds}s`}</strong>;
  }
};

const Banner = () => {
  const { library, account } = useActiveWeb3React();

  const addMainnet = useCallback(() => {
    setupNetwork({ library });
  }, [library]);

  const addToken = useCallback(() => {
    registerToken(hfgContract, "HFG", 18);
  }, []);

  // const addUSDT = useCallback(() => {
  //   registerToken(
  //     usdtContract,
  //     "USDT",
  //     6,
  //     "https://polygonscan.com/token/images/tether_32.png"
  //   );
  // }, []);

  return (
    <>
      <ToastContainer />
      <section className="banner">
        <CContainer>
          <div className="topInformation text-center">
            <CRow className="align-items-center">
              <CCol lg="6">
                <h1 className="mx-auto">
                  HashFair Games Unleashes NFT Staking on the Polygon Chain.
                </h1>
                <div className="buttonGroup mt-4">
                  <CButton color="btnMU mx-2 mb-4" onClick={addMainnet}>
                    <span>Add Mainnet</span>
                  </CButton>
                  <CButton color="btnMU mx-2 mb-4" onClick={addToken}>
                    <span>Add HFG</span>
                  </CButton>
                  <CButton
                    color="btnMU mx-2 mb-4"
                    onClick={() => {
                      window.location.href = "https://plan.hashfair.network/";
                    }}
                  >
                    <span>Buy NFT</span>
                  </CButton>
                  <CButton
                    color="btnMU mx-2 mb-4"
                    onClick={() => {
                      window.location.href = "https://app.uniswap.org/swap?chain=polygon&inputCurrency=0xc2132D05D31c914a87C6611C1[…]e8F&outputCurrency=0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf";
                    }}
                    
                  >
                    <span>Buy HFG</span>
                  </CButton>
                </div>
              </CCol>
              <CCol lg="6">
                <LazyLoad
                  once
                  placeholder={
                    <svg
                      className="loaderAnim"
                      version="1.1"
                      id="L9"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 100 100"
                      enable-background="new 0 0 0 0"
                    >
                      <path
                        fill="#ffffff"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                      >
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          dur="1s"
                          from="0 50 50"
                          to="360 50 50"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  }
                >
                  <Lottie loop animationData={nftLvl7} play />
                </LazyLoad>
              </CCol>
            </CRow>
          </div>
          {/* <div className="listViewBx mb-4 px-3 py-3">
            <div className="inrInfoBx">
              <span >
                <strong className="date">
                  Staking Rewards Starts In
                </strong>
              </span>
              <span className="countbox">
                <Countdown

                  date={new Date(Date.UTC(2023, 7, 1, 0, 0, 0))}
                  renderer={renderer}
                />

              </span>
              <span>
                <strong className="date">August 1st 2023</strong>
              </span>
            </div>
          </div> */}

          <div className="listViewBx ms:mt-5 pt-4">
            <CRow className="align-items-center">
              <Statistics />
            </CRow>
          </div>
        </CContainer>
      </section>
      {account && (
        <section className="nftBalance">
          <Balance />
        </section>
      )}
      <section className="informationMU">
        <YourNFT />
      </section>
      {/* <section className="ournft" id="buyNFT">
        <NftCards />
      </section> */}
      <section className="newssec">
        <News />
      </section>
      <section className="informationMU">
        <CContainer>
          <CRow className="align-items-center">
            <CCol md="12">
              <div className="infotxt">
                <h3>Benefits of Staking Hashfair NFT:</h3>
                <div className="benefitcontent">
                  <p>
                    <span>Fair distribution of profits:</span> The staking pool
                    calculates the shares of each stakeholder based on the
                    number of NFTs they have staked. This means that
                    stakeholders who have staked more NFTs will receive a larger
                    share of the profits.
                  </p>
                  <p>
                    <span>Community engagement and influence:</span> NFT holders
                    can actively participate in shaping the game's development,
                    mechanics, and future updates. The ownership of certain NFTs
                    grants holders a specific supervisory share power, which
                    determines their influence in crucial decisions.
                  </p>
                  <p>
                    <span>Multipliers and enhanced rewards:</span> HashFair
                    assigns different multipliers to each NFT, ranging from 1 to
                    2. These multipliers determine the stakeholder's share of
                    profits from the staking pool. Stakeholders who hold NFTs
                    with higher multipliers will receive a greater proportion of
                    the profits compared to those with lower multipliers.
                  </p>
                </div>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>
    </>
  );
};

export default Banner;
