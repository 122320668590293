import { useCallback } from "react"
import { useStake } from "../state/account/hooks"
import { useState } from "react";
import LazyLoad from "react-lazyload";
import { useApproveNFT } from "./hooks/useApproveNFT";

export const NFTMeta = ({ nftAddress, tokenId, shares, amount, imageKey, index }) => {
    const { stakeNFT, approveNFT } = useStake({ nftAddress })
    const [isInTrn, setTransaction] = useState(false);
    const { isApproved } = useApproveNFT({ nftAddress })

    const approveBtn = useCallback(
        async () => {
            setTransaction(true)
            await approveNFT()
            setTransaction(false)
        },
        [approveNFT, setTransaction],
    )

    const stakeBtn = useCallback(
        async () => {
            setTransaction(true)
            await stakeNFT({ nftAddress, tokenId })
            setTransaction(false)
        },
        [stakeNFT, setTransaction, nftAddress, tokenId]
    )

    return (
        <div key={index}>
            <div className="popular_gaming_thumb">
                <a href="#!">
                    {/* <span className="nftboxtitle">{`${item.value}`}</span> */}
                    <LazyLoad
                        className="nftsvideo"
                        once
                        placeholder={
                            <svg
                                className="loaderAnim"
                                version="1.1"
                                id="L9"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 100 100"
                                enableBackground="new 0 0 0 0"
                            >
                                <path
                                    fill="#ffffff"
                                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                >
                                    <animateTransform
                                        attributeName="transform"
                                        attributeType="XML"
                                        type="rotate"
                                        dur="1s"
                                        from="0 50 50"
                                        to="360 50 50"
                                        repeatCount="indefinite"
                                    />
                                </path>
                            </svg>
                        }
                    >
                        <div className="leftinfo">
                            <span className="labeltag">NFT Price</span>
                            <span>{amount} USDT</span>
                        </div>
                        <div className="rightinfo">
                            <span>Share Power</span>
                            <span>{shares}</span>
                        </div>
                        <div className="leftinfo">
                            <span className="labeltag">Token ID</span>
                            <span>{tokenId}</span>
                        </div>
                        <div className="itembox">
                            <img src={imageKey} alt="no-text" />
                        </div>
                        <div className="stakebtnarea">
                            {isApproved && <button onClick={stakeBtn} className="stakebtn opacity-50">
                                {!isInTrn && "Stake"}
                                {isInTrn && "Staking..."}
                            </button>}
                            {!isApproved && <button className="stakebtn" onClick={approveBtn}>
                                {!isInTrn && "Approve NFT"}
                                {isInTrn && "Approving..."}
                            </button>}
                        </div>
                    </LazyLoad>
                </a>
            </div>
        </div>
    )
}