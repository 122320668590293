import React, { useEffect, useState } from "react";
// import ReactPlayer from 'react-player';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";
import "swiper/swiper.css";
import { useNFT } from "../hooks/useNFT";
import { nftTitles } from "../config/nfts";
import { CButton, CCol, CRow } from "@coreui/react";
import { NFTMeta } from "./NFTMeta";
import { useSelector } from "react-redux";
import useActiveWeb3React from "../hooks/useWeb3";
SwiperCore.use([Navigation, Pagination, Autoplay]);

const YourNFT = () => {
  const { account } = useActiveWeb3React();
  const [nftCollection, setNFTCollection] = useState([]);
  const nfts = useNFT();

  const { userNfts: userNFTs } = useSelector((state) => state.stake);

  useEffect(() => {
    (() => {
      let allNFTs = [];
      for (let n in nfts) {
        let nftAddress = nfts[n].address;
        let nftMeta = nftTitles.find(
          (nft) => nft.address.toLowerCase() === nftAddress.toLowerCase()
        );
        allNFTs.push({ ...nftMeta, ...nfts[n] });
      }
      setNFTCollection(allNFTs);
    })();
  }, [nfts, setNFTCollection]);

  return (
    <>
      {/* NFT section start */}
      <section className="nftMain" id="nft">
        <div className="container">
          <div className="section-headline text-center">
            <h3 className="nftheadline">Your NFTs</h3>
            <p>
              your available NFT's are listed below, you can stake them to earn
            </p>
          </div>
          <div className="popular_gaming_inner">
            {account && nfts.length > 0 && (
              <CRow className="reasultPageSwiper flex">
                {nftCollection.map((item, index) => (
                  <CCol className="cardsec" sm={6} md={5} lg={4} xl={3}>
                    <NFTMeta
                      index={index}
                      nftAddress={item.address}
                      shares={item.shares}
                      amount={item.amount}
                      tokenId={item.tokenId}
                      imageKey={item.key}
                    />
                  </CCol>
                ))}
              </CRow>
            )}

            {account && nfts.length === 0 && (
              <div className="text-center">
                {userNFTs.length === 0 && (
                  <div className="text-center">
                    <h2>No NFTs Found!</h2>
                    <div className="d-flex justify-content-center">
                      <CButton
                        color="btnMU mx-2 mb-4 mt-4"
                        onClick={() => {
                          window.location.href =
                            "https://plan.hashfair.network/";
                        }}
                      >
                        <span>Buy Now</span>
                      </CButton>
                    </div>
                  </div>
                )}
                {userNFTs.length > 0 && (
                  <div className="text-center">
                    <h2>All NFTs Staked</h2>
                    <div className="d-flex justify-content-center">
                      <CButton
                        color="btnMU mx-2 mb-4 mt-4"
                        onClick={() => {
                          window.location.href =
                            "https://plan.hashfair.network/";
                        }}
                      >
                        <span>Buy More</span>
                      </CButton>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!account && (
              <div className="text-center">
                <h2>Connect Wallet to view NFTs</h2>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
export default YourNFT;
