import React from 'react'
import { CContainer, CRow } from '@coreui/react';

import { useAccountInfo } from '../state/account/hooks';
import { useSelector } from '../redux/store';
import { useStakeNFT } from '../hooks/useNFT';
import { StakeNFTCard } from './StakeNFTCard';
import { NFTTab } from './NFTTab';

export const Balance = () => {

    const { userNfts } = useSelector((state) => state.stake)

    const activeNFTs = userNfts.filter((nft) => {
        if (nft.claimCount < 24) {
            if (nft.id.toLowerCase() === "0x21B7dD3D394DB6fB4F5B01f482CCa35779946d8c-0".toLowerCase()) {

                return false
            }
            return true
        }
        return false
    })
    const inactiveNFTs = userNfts.filter((nft) => (nft.claimCount === 24))

    // console.debug("nfts loaded", userNfts)

    useAccountInfo()
    useStakeNFT()

    return (
        <CContainer>
            <div className="container">
                <div className="section-headline text-center">
                    <h3 className="nftheadline">My Staked NFTs</h3>
                    <p>
                        your staked NFT's are listed below, claim and check your balance
                    </p>
                </div>
            </div>
            <div className='balanceViewBx mb-5 mb-md-0'>
                <NFTTab
                    active={
                        <div className='text-center'>
                            <h2>Active NFTs</h2>
                            <CRow className="align-items-center flex-wrap justify-content-center">

                                {
                                    activeNFTs.map((nft, index) => {
                                        return <StakeNFTCard active nft={nft} key={index} />
                                    })
                                }

                                {
                                    activeNFTs.length === 0 && <h5 className="text-center">No NFTs Staked</h5>
                                }
                            </CRow>
                        </div>
                    }
                    inactive={
                        <div className='text-center'>
                            <h2>Inactive NFTs</h2>
                            <CRow className="align-items-center flex-wrap justify-content-center">

                                {
                                    inactiveNFTs.map((nft, index) => {
                                        return <StakeNFTCard active={false} nft={nft} key={index} />
                                    })
                                }

                                {
                                    inactiveNFTs.length === 0 && <h5 className="text-center">No NFTs Staked</h5>
                                }
                            </CRow>
                        </div>
                    }
                />
            </div>
        </CContainer>
    )
}