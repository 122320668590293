import { formatEther } from "ethers/lib/utils";

function shortAddress(address) {
    return `${address.substr(0, 4)}...${address.substr(-4)}`;
}

function formatNumber(val, decimals = 4) {
    return parseFloat(parseFloat(formatEther(val)).toFixed(decimals)).toLocaleString()
}

function toLocale(val, decimals = 4) {
    const beforeDot = val.toString().split('.')[0]
    const afterDot = val.toString().split('.')[1]
    let final = ''
    if (afterDot && afterDot.length > decimals) {
        final = beforeDot + '.' + afterDot.substr(0, decimals)
        return final
    }
    return parseFloat(parseFloat(val).toFixed(decimals)).toLocaleString()
}

export {
    shortAddress,
    formatNumber,
    toLocale
}