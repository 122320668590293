import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { fetchStakeMeta, fetchUserStakeNFT } from "."
import useActiveWeb3React from "../../hooks/useWeb3"
import { useCallback } from "react"

export const useStakeInfo = () => {
    const dispatch = useDispatch()
    const { account } = useActiveWeb3React()
    const fetchInfo = useCallback(() => {
        if(account) {
            dispatch(fetchUserStakeNFT(account));
        }
    }, [dispatch, account])

    const fetchMeta = useCallback(( ) => {
        dispatch(fetchStakeMeta())
    }, [dispatch])

    useEffect(() => {
        fetchInfo()
        fetchMeta()
    }
    , [fetchInfo, fetchMeta])

    useEffect(( ) => {
        const interval = setInterval(() => {
            fetchInfo()
            fetchMeta()
        }, 6500)
        return () => clearInterval(interval)
    }, [fetchInfo, fetchMeta])
}