export const stakeContract = "0x72D99893a0dD44ed7931c4194A343e83492927E4"
export const hfgContract = "0x681E04eA8F903da45A6520eE1e5F4B21b4503fcf"
export const usdtContract = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"

export const multiCallAddress = "0x4445286592CaADEB59917f16826B964C3e7B2D36" //"0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B"
export const EXPLORER_LINKS = 'https://polygonscan.com'
export const BASE_URL = 'https://stake-nft.hashfair.io/'
export const chainRPC = "https://polygon-rpc.com/" //https://alpha-alpha-bird.matic-testnet.quiknode.pro/2d46a76d8bd8d402b6d048a255c308f77f684057/
export const networkName = "Polygon Mainnet"
export const minWithdraw = 10
export const defaultNetwork = 137 // 80001


export const graphURL = "https://gateway-arbitrum.network.thegraph.com/api/07954754ae20ec2d7d2767afaf8fd317/subgraphs/id/4i6wjXTwfiXbTpztzZMQgCqE3dseWG9fmVABX6syyGQy"
export const stakeURL = "https://gateway-arbitrum.network.thegraph.com/api/07954754ae20ec2d7d2767afaf8fd317/subgraphs/id/EndzJNfLr3cooYaP2e9s92xvnPopbwxtsaZXaaaZ2dcH"

export const blacklist = [
    // "0x9be4c933c6c71e6d0dced4e63ad921ce67b2ce53",
    // "0x63c3FBDc241c67B9096E1Aa1233451b32F96B743",
    // "0x63c3FBDc241c67B9096E1Aa1233451b32F96B743",
    // "0x8937fd296c46f445cc503d8b61174842708a7da4",
    // "0xf56AC87612E4368C231a98d5B04686Dd311aAa8c",
    // "0xd1f2079371b53148b7273325ee3a321d1eb1726d",
    // "0x40a8af0fc6a240625b86c518825dad7855eeb65c",
    // "0xe4d4a0dca5d215a7bfa498d315aed166e782bd17",
    // "0x49eb294450fd71c9b7213643853cf340d36592d2",
    // "0xa5EAa031638c07D163474Db16E2ae98c864a3ca5",
    // "0x5d4349d7461062cd324169e3e8e1577de3d977de",
    // "0x5d4349d7461062cd324169e3e8e1577de3d977de",
    // "0xc878d3e2dd3aa40f57fa061458cf95032657f7f3",
    // "0xd7283a56f2bfe0b9ae96fbe618ccc37530448a04",
    // "0xd7283a56f2bfe0b9ae96fbe618ccc37530448a04",
    // "0xb251550b8865d612be6fa5f707687ae35d469da0",
    "0x0c0f65ba9f6802ee06fb8d82dd0508f21075294b" // exclude in +10 claims
]

export const feeStructure = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 10,
    7: 15,
    8: 20,
    9: 25,
    10: 30,
    11: 35,
    12: 40,
    13: 45,
    14: 50,
    15: 55,
    16: 60,
    17: 65,
    18: 70,
    19: 75,
    20: 80,
    21: 85,
    22: 90,
    23: 95
}