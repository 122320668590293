import { useCallback } from "react";
import { useEffect, useMemo, useState } from "react"

export const useHFGPrice = () => {
    const [price, setPrice] = useState(0);

    const getPrice = useCallback(async () => {
        const response = await fetch("https://hfg-price-api.vercel.app/hfg-price");
        const data = await response.json();
        setPrice(data["price"]);
    }, [])

    useEffect(() => {
        getPrice()
    }, [getPrice])

    useEffect(() => {
        const interval = setInterval(() => {
            getPrice();
        }, 30000);
        return () => clearInterval(interval);
    }, [getPrice])

    return useMemo(() => {
        return {
            price
        }
    }, [price])
}