import { useState } from "react"
import { useCallback } from "react"
import { useMemo } from "react"
import { useEffect } from "react"
import useActiveWeb3React from "./useWeb3"
import { fetchClaims } from "../utils/fetchStakes"

export const useFetchClaims = (idx) => {
    const [claims, setClaims] = useState([])
    const [loading, setLoading] = useState(false)

    const { account } = useActiveWeb3React()
    const fetchClaimsCallback = useCallback(async () => {
        if (account) {
            setLoading(true)
            const pool = await fetchClaims(account, idx)
            setClaims(pool)
            setLoading(false)
        }
    }, [account, idx])

    useEffect(() => {
        fetchClaimsCallback()
    }, [fetchClaimsCallback])

    return useMemo(() => {
        return {
            claims,
            loading
        }
    }, [claims, loading])
}