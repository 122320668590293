import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useDayData } from "../hooks/useDayData";
import { CContainer } from "@coreui/react";

const News = () => {
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    768: {
      items: 3,
      itemsFit: "fill",
    },
    1400: {
      items: 4,
    },
  };
  const items = useDayData();
  return (
    <>
      <section className="news">
      <CContainer>
        <div className="container">
          <div className="section-headline text-center">
            <h3 className="nftheadline">Daily Pool Data</h3>
            <p>
              check last 7 days pool data, and see how much you can earn daily
            </p>
          </div>
          {/* <div className="newsbox">
            <div className="roundwrap">
            <div className="roundbg">
              <img src={number1} width={150} height={150} alt="01 image" />
            </div>
            </div>
            <div className="infolabel">
              <div className="label1">
                <span>LoremIpsum</span>
                <span>300</span>
              </div>
              <div className="label1">
                <span>LoremIpsum</span>
                <span>300</span>
              </div>
              <div className="label1">
                <span>LoremIpsum</span>
                <span>300</span>
              </div>
              <div className="label1">
                <span>LoremIpsum</span>
                <span>300</span>
              </div>
            </div>
          </div> */}
          {
            items.length === 0 && <h5 className="text-center">No Data Found</h5>
          }
          <AliceCarousel
            mouseTracking
            // infinite
            disableButtonsControls={false}
            items={items}
            responsive={responsive}
          />
        </div>
        </CContainer>
      </section>
    </>
  );
};

export default News;
