import React from 'react'
import { CRow, CCol } from '@coreui/react';

import Collected from '../Assets/images/nft-icon/Collected.png'
import Generated from '../Assets/images/nft-icon/Generated.png'
import Pending_Days from '../Assets/images/nft-icon/Pending_Days.png'
import Pending_Icon from '../Assets/images/nft-icon/Pending_Icon.png'

import { useSelector } from '../redux/store'
import { useStakeInfo } from '../state/stake/hooks'
import Countdown from 'react-countdown';
import { toLocale } from '../utils';
import { useHFGPrice } from '../hooks/useHFGPrice';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <strong>generating</strong>;
    } else {
        return (
            <strong>{`${hours}h ${minutes}m ${seconds}s`}</strong>
        );
    }
};

export const Statistics = () => {
    const { nextUpdate, totalCollected, perShare, totalShares, totalDistributed } = useSelector(state => state.stake)
    useStakeInfo()

    const { price } = useHFGPrice()

    return (
        <CCol md="12">
            <CRow xs={{ cols: 1 }} md={{ cols: 3 }} lg={{ cols: 3 }} xl={{ cols: 5 }}  className="justify-content-center gap-2 gap-md-0">
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={Collected} alt="Collected" />
                        </div>
                        <strong>
                            {toLocale(perShare, 6)} HFG<br />
                        </strong>
                        <p>${toLocale(price * perShare)}</p>
                        <p>Last Per Share</p>
                    </div>
                </CCol>
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={Generated} alt="Generated" />
                        </div>
                        <strong>
                            {toLocale(totalCollected)} HFG
                        </strong>
                        <p>${toLocale(price * totalCollected, 2)}</p>
                        <p>Total Collected</p>
                    </div>
                </CCol>
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={Collected} alt="Collected" />
                        </div>
                        <strong>
                            {toLocale(totalDistributed, 2)} HFG
                        </strong>
                        <p>${toLocale(price * totalDistributed, 2)}</p>
                        <p>Total Distributed</p>
                    </div>
                </CCol>
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={Pending_Icon} alt="Pending Icon" />
                        </div>
                        <strong>
                            {toLocale(totalShares)}
                        </strong>
                        <p>Total Shares</p>
                    </div>
                </CCol>
                <CCol sm="auto" className='mb-2'>
                    <div className='inrInfoBx'>
                        <div className='imginrBx'>
                            <img src={Pending_Days} alt="Pending Days" />
                        </div>
                        {/* <h2> */}
                        {nextUpdate !== 0 && <Countdown date={new Date(nextUpdate * 1000)} renderer={renderer} />}
                        {nextUpdate === 0 && '...'}
                        {/* <strong>....</strong> */}
                        {/* </h2> */}
                        <p>Next Share Allocation In</p>
                    </div>
                </CCol>

            </CRow>
        </CCol>
    )
}