import {createSlice} from "@reduxjs/toolkit";
import { fetchStakeMeta, fetchUserStakeNFT } from "../../state/stake";

const initialState = {
    userNfts: [],
    userClaims: [],
    perShare: 0,
    lastUpdated: 0,
    totalShares: 0,
    totalCollected: 0,
    nextUpdate: 0,
    totalDistributed: 0,
    maxInterval: 0,
    startStake: 0
}

const stakeSlice = createSlice({
    name: 'stake',
    initialState,
    extraReducers: builder => {

        builder.addCase(fetchStakeMeta.fulfilled, (state, action) => {
            const data = action.payload
            state.perShare = data.perShare
            state.lastUpdated = data.lastUpdated
            state.totalShares = data.totalShares
            state.totalCollected = data.totalCollected
            state.nextUpdate = data.nextUpdate
            state.totalDistributed = data.totalDistributed
            state.maxInterval = data.maxInterval
        })

        builder.addCase(fetchUserStakeNFT.fulfilled, (state, action) => {
            const data = action.payload
            state.userNfts = data.nfts
            state.userClaims = data.claims
        })
    }
})

export default stakeSlice.reducer