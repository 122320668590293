import axios from "axios";
import { graphURL, stakeURL } from "../config";

export const fetchNFTs = (account) => {
    return new Promise(async (res, rej) => {
        try {
            const options = {
                method: 'POST',
                url: graphURL,
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    query: `{
                        userNFT(id:"${account.toLowerCase()}") {
                            id
                            nftIds {
                                tokenId
                                address
                            }
                        }
                    }`
                }
            };

            const response = await axios.request(options);
            const resData = response.data;
            // // console.debug(resData.data.userNFT.nftIds)
            if (resData.data.userNFT) {
                res(resData.data.userNFT.nftIds)
            } else {
                res([])
            }
        } catch (err) {
            res([])
        }
    })
}

export const fetchDayDatas = () => {
    return new Promise(async (res, rej) => {
        try {
            const options = {
                method: 'POST',
                url: stakeURL,
                headers: {
                    'content-type': 'application/json'
                },
                data: {
                    query: `{
                        dayDistributions(orderBy:blockTimestamp, orderDirection: desc, first: 7, where:{blockTimestamp_gt: 1690878961}) {
                            id
                            amount
                            shares
                            perShare
                            blockTimestamp
                            transactionHash
                        }
                    }`
                }
            };

            const response = await axios.request(options);
            const resData = response.data;
            // console.debug(resData.data.dayDistributions)
            if (resData.data.dayDistributions) {
                res(resData.data.dayDistributions)
            } else {
                res([])
            }
        } catch (err) {
            // console.debug(err)
            res([])
        }
    })
}