import useActiveWeb3React from "./useWeb3";
import {useMemo} from "react";
import {getContract} from "../utils/getContract";
import multiCallAbi from '../config/abis/multicall.json';
import stakeABI from '../config/abis/stakeABI.json';
import tokenABI from '../config/abis/tokenABI.json';
import nftABI from '../config/abis/IERC1155.json';
import nftManagerABI from '../config/abis/nftManager.json'

import {multiCallAddress, stakeContract} from "../config";

export const useContract = (address, abi) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getContract(address, abi, library.getSigner()), [address, abi, library]);
}

export function useMulticallContract() {
    return useContract(multiCallAddress, multiCallAbi);
}

export function useStakeContract() {
    return useContract(stakeContract, stakeABI)
}

export function useTokenContract(tokenAddress) {
    return useContract(tokenAddress, tokenABI)
}

export function useNFTContract({nftAddress}) {
    return useContract(nftAddress, nftABI)
}

export function useNFTManager() {
    return useContract("0x0512b356967e9Ef010EEE775476F13415e3343Ca", nftManagerABI)
}