import { shortAddress } from "."
import { handleAsyncOperation, showError, showFulfill, showPromise } from "./pageHelpers"

export const _stakeNFT = async ( contractInst, nftAddress, tokenId ) => {
    try {
        console.log(`Initiaiting txn..`)
        let txn = await contractInst.stakeNFT(nftAddress, tokenId)
        // showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        // await showPromise(txn.wait, `Waiting for transaction to be confirmed..`, `Error while waiting for transaction to be confirmed`, `Transaction confirmed!`)
        let res = await handleAsyncOperation(txn)
        if ( res ){
            showFulfill(`NFT Sucessfully Staked`, 'Transaction Confirmed')
        }
    } catch ( err ) {
        console.log(err)
        showError(err.reason ? err.reason : (err.data ? err.data.message: err.message))
    }
}

export const _approve = async ( tokenInst, spender ) => {
    try {
        let txn = await tokenInst.setApprovalForAll(spender, true)
        // showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        // await showPromise(txn.wait, `Waiting for transaction to be confirmed..`, `Error while waiting for transaction to be confirmed`, `Transaction confirmed!`)
        let res = await handleAsyncOperation(txn)
        if ( res ){
            showFulfill(`Sucessfully approved NFT for staking!`, 'Transaction Confirmed')
        }
    } catch (err) {
        showError(err.reason ? err.reason : (err.data ? err.data.message: err.message))
    }
}


export const _claim = async ( stakeInstance, idx ) => {
    try {
        // showError("try again later")
        // return
        let txn = await stakeInstance.claimProfits(idx)
        // showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        let res = await handleAsyncOperation(txn)
        // await showPromise(txn.wait, `Waiting for transaction to be confirmed..`, `Error while waiting for transaction to be confirmed`, `Transaction confirmed!`)
        if ( res ){
            showFulfill(`Sucessfully claimed dividends!`, 'Transaction Confirmed')
        }
    } catch ( err ) {
        showError(err.reason ? err.reason : (err.data ? err.data.message: err.message))
    }
}
