import nftLvl1 from "../Assets/images/nfts/Lvl1.gif";
import nftLvl2 from "../Assets/images/nfts/Lvl2.gif";
import nftLvl3 from "../Assets/images/nfts/Lvl3.gif";
import nftLvl4 from "../Assets/images/nfts/Lvl4.gif";
import nftLvl5 from "../Assets/images/nfts/Lvl5.gif";
import nftLvl6 from "../Assets/images/nfts/Lvl6.gif";
import nftLvl7 from "../Assets/images/nfts/Lvl7.gif";

export const nfts = [
    {
        name: "Blinkers",
        value: 100,
        address: "0xc4d241e10c40d7ee50f00b42cdcc27982e607886"
    },
    {
        name: "SlotterSpot",
        value: 500,
        address: "0x1B923961b3Ab511391326aF3367a85F2FC44c08B"
    },
    {
        name: "Spinwin",
        value: 1000,
        address: "0xeb00fabe43f89609eD728011718bDBAa2ae3F743"
    },
    {
        name: "Wild Card",
        value: 2000,
        address: "0xC35Eb08b3Eb9a8cDd4d0dF30ea1B23bE488F27Df"
    },
    {
        name: "Thunderbird",
        value: 5000,
        address: "0xe8068734bA9026ef7893119F4a722c8F83bB0894"
    },
    {
        name: "Jackpot",
        value: 10000,
        address: "0x8617b6b37604d8268b7dF0ADe66682B9AB013994"
    },
    {
        name: "Kingpin",
        value: 50000,
        address: "0xE41a3E3d76A6B67bB351Cf913BC3573392dc951F"
    }
]

export const nftTitles = [
    {
        key: nftLvl1,
        address: "0xc4d241e10c40d7ee50f00b42cdcc27982e607886",
        value: "BLINKERS",
        amount: 100,
        shares: 100
    },
    {
        key: nftLvl2,
        address: "0x1B923961b3Ab511391326aF3367a85F2FC44c08B",
        value: "SLOTTERSPOT",
        amount: 500,
        shares: 550
    },
    {
        key: nftLvl3,
        address: "0xeb00fabe43f89609eD728011718bDBAa2ae3F743",
        value: "SPINWIN",
        amount: 1000,
        shares: 1200
    },
    {
        key: nftLvl4,
        address: "0xC35Eb08b3Eb9a8cDd4d0dF30ea1B23bE488F27Df",
        value: "WILDCARD",
        amount: 2000,
        shares: 2600
    },
    {
        key: nftLvl5,
        address: "0xe8068734bA9026ef7893119F4a722c8F83bB0894",
        value: "THUNDERBIRD",
        amount: 5000,
        shares: 7500
    },
    {
        key: nftLvl6,
        address: "0x8617b6b37604d8268b7dF0ADe66682B9AB013994",
        value: "JACKPOT",
        amount: 10000,
        shares: 17000
    },
    {
        key: nftLvl7,
        address: "0xE41a3E3d76A6B67bB351Cf913BC3573392dc951F",
        value: "KINGPIN",
        amount: 50000,
        shares: 100000
    },
];