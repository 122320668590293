import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { Web3ReactProvider } from '@web3-react/core';
import Lottie from 'react-lottie';
import { Toaster } from "react-hot-toast";
import { Buffer } from 'buffer';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'sweetalert2/src/sweetalert2.scss';
import '../src/Assets/css/main.css';
import store from "./redux/store";
import { getLibrary } from './utils/web3React';
import useDirectConnect from './hooks/useDirectConnect';
import Layout from './Pages/Layout';
import Home from './Pages/Home';
import About from './Pages/About';
import loaderIcon from './Assets/images/logo.json';
import BigBangStarField from 'react-big-bang-star-field';
import OurNft from './Components/NftCards';

const AppRoutes = () => {
  useDirectConnect()
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<OurNft />} />
      </Route>
    </Routes>
  )
}

const App = () => {
  window.Buffer = Buffer

  const loaderOption = {
    loop: true,
    autoplay: true,
    animationData: loaderIcon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate page loading
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <div className='loaderMain'>
            <Lottie
              options={loaderOption}
              height={200}
              width={200}
            />
          </div>
        </>
      ) : (
        <Web3ReactProvider getLibrary={getLibrary}>
          <Provider store={store}>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
            <Toaster />
            <BigBangStarField
              className="bgBody"
              numStars={150}
              maxStarSpeed={5}
              scale={1}
              starColor="252, 255, 255"
            />
          </Provider>
        </Web3ReactProvider>
      )}
    </>
  );
}

export default App;
