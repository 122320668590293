import React from 'react'
import Banner from '../Components/Banner'
// import Popuphealine from '../Components/Popupheadline'


const Home = () => {
    return (
        <>
            <Banner />
            {/* <Popuphealine /> */}
        </>
    )
}

export default Home