import { createAsyncThunk } from "@reduxjs/toolkit";
import { stakeContract } from "../../config";
import stakeABI from "../../config/abis/stakeABI.json"
import { getContract } from "../../utils/getContract";
import { formatEther } from "ethers/lib/utils";
import { fetchStakeNFTs } from "../../utils/fetchStakes";
import { fetchDayDatas } from "../../utils/fetchNFTs";

export const fetchDayData = (async () => {
    try {
        const data = await fetchDayDatas()
        return data
    } catch (err) {
        // console.debug(err)
        return []
    }
})

export const fetchStakeInfo = (async (address, idx) => {
    try {
        const stakeInstance = getContract(stakeContract, stakeABI)
        const user = await stakeInstance.user(address, idx)
        const shares = user.shares
        const totalClaimed = user.totalClaimed
        const claimCount = user.claimCount
        const dividendGenerated = await stakeInstance.getClaimableAmount(address, idx)

        // // console.debug(shares, totalClaimed, claimCount, dividendGenerated)

        return {
            shares: shares.toNumber(),
            totalClaimed: formatEther(totalClaimed),
            dividendGenerated: formatEther(dividendGenerated),
            claimCount: claimCount.toNumber()
        }
    } catch (err) {
        // console.debug(err)
        return {
            shares: 0,
            totalClaimed: 0,
            dividendGenerated: 0,
            claimCount: 0
        }
    }
})


export const fetchStakeMeta = createAsyncThunk('stake/fetchStakeMeta', async () => {
    try {
        const stakeInstance = getContract(stakeContract, stakeABI)
        const pool = await stakeInstance.pool()
        const totalShares = pool.totalShare
        const totalCollected = pool.totalCollected
        const lastUpdate = await stakeInstance.lastUpdate()
        const maxInterval = await stakeInstance.maxInterval()
        const startStake = await stakeInstance.startStake()
        const nextUpdate = lastUpdate.toNumber() + maxInterval.toNumber()
        const lastDayDate = await stakeInstance.dayData(lastUpdate)
        const perShare = lastDayDate.perShareGenerated
        const totalDistributed = pool.totalAllocated

        return {
            totalShares: totalShares.toNumber(),
            totalCollected: formatEther(totalCollected),
            lastUpdated: lastUpdate.toNumber(),
            perShare: formatEther(perShare),
            nextUpdate: nextUpdate,
            totalDistributed: formatEther(totalDistributed),
            maxInterval: maxInterval.toNumber(),
            startStake: startStake.toNumber()
        }
    } catch (err) {
        console.log(err)
        return {
            totalShares: 0,
            totalCollected: 0,
            lastUpdated: 0,
            perShare: 0,
            nextUpdate: 0,
            totalDistributed: 0,
            maxInterval: 0,
            startStake: 0
        }
    }
})

export const fetchUserStakeNFT = createAsyncThunk('stake/fetchStakeNFT', async (address) => {
    try {
        const pool = await fetchStakeNFTs(address)
        return {
            nfts: pool.nfts,
            claims: pool.claims
        }
    } catch (err) {
        return {
            nfts: [],
            claims: []
        }
    }
})