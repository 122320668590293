import { useCallback, useEffect, useMemo } from "react"
import useActiveWeb3React from "../../hooks/useWeb3"
import { fetchAccountApprove } from "../../state/account/fetchAccountInfo"
import { useState } from "react"

export const useApproveNFT = ({ nftAddress }) => {
    const { account } = useActiveWeb3React()
    const [isApproved, setApproved] = useState(false)

    const checkIfApprove = useCallback(async () => {
        if (!account || !nftAddress) return
        const isApprovedForAll = await fetchAccountApprove({ address: account, nftAddress })
        // console.debug("isApprovedForAll", isApprovedForAll)
        setApproved(isApprovedForAll)
    }, [account, nftAddress])

    useEffect(() => {
        const inter = setInterval(checkIfApprove, 6500)
        return () => clearInterval(inter)
    }, [checkIfApprove])

    useEffect(() => {
        checkIfApprove()
    }, [checkIfApprove])

    return useMemo(() => ({ isApproved }), [isApproved])
}