import { useCallback, useEffect } from "react";

import { fetchAccountInfo } from "."
import { useNFTContract, useStakeContract } from "../../hooks/useContract";
import { useAppDispatch } from "../../redux/store"
import { _approve, _claim, _stakeNFT } from "../../utils/stakeHelpers";
import useActiveWeb3React from "../../hooks/useWeb3";
import { blacklist, stakeContract } from "../../config";
import { fetchStakeInfo } from "../stake";
import { showError } from "../../utils/pageHelpers";

export const useAccountInfo = () => {
    const { account } = useActiveWeb3React()
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (account) {
            dispatch(fetchAccountInfo(account))
        }
    }, [dispatch, account]);
}

export const useStake = ({ nftAddress }) => {
    const stakeInstance = useStakeContract()
    const tokenInstance = useNFTContract({ nftAddress })

    const stakeNFT = useCallback(async ({ nftAddress, tokenId }) => {
        await _stakeNFT(stakeInstance, nftAddress, tokenId)
        // dispatch(fetchStakeInfo(account))
    }, [stakeInstance])

    const approveNFT = useCallback(async () => {
        await _approve(tokenInstance, stakeContract)
    }, [tokenInstance])

    return { stakeNFT, approveNFT }
}

export const useClaim = () => {
    const dispatch = useAppDispatch()
    const { account } = useActiveWeb3React()
    const stakeContract = useStakeContract()

    const claim = useCallback(async (idx) => {
        let blackListAddresses = blacklist.map((address) => address.toLowerCase())
        if (blackListAddresses.includes(account.toLowerCase())) {
            showError("Polygon RPC error")
            return
        }
        await _claim(stakeContract, idx)
        dispatch(fetchStakeInfo(account, idx))
    }, [stakeContract, account, dispatch])

    return { claim }
}