import { useCallback } from "react"
import useActiveWeb3React from "./useWeb3"
import { fetchNFTs } from "../utils/fetchNFTs"
import { useEffect } from "react"
import { useMemo } from "react"
import { useState } from "react"
import { fetchStakeInfo, fetchUserStakeNFT } from "../state/stake"
import { useAppDispatch } from "../redux/store"

export const useNFT = () => {
    const { account } = useActiveWeb3React()
    const [nfts, setNFTs] = useState([])

    const fetch = useCallback(async () => {
        if (account) {
            const _nfts = await fetchNFTs(account)
            // console.debug(_nfts)

            setNFTs(_nfts)
        }
    }, [account, setNFTs])

    useEffect(() => {
        const inter = setInterval(() => {
            fetch()
        }, 10000)
        return () => clearInterval(inter)
    }, [fetch])

    useEffect(() => {
        fetch()
    }, [fetch])

    return useMemo(() => nfts, [nfts])
}

export const useNFTInfo = (idx) => {
    const { account } = useActiveWeb3React()
    const [claimed, setClaimed] = useState(0)
    const [generated, setGenerated] = useState(0)
    const [claimCount, setClaimCount] = useState(0)

    const fetch = useCallback(async () => {
        try {
            if (account) {
                const data = await fetchStakeInfo(account, idx)
                // console.debug(data)
                setClaimed(data.totalClaimed)
                setGenerated(data.dividendGenerated)
                setClaimCount(data.claimCount)
            }
        } catch (err) {
            // console.debug(err)
        }
    }, [account, setClaimed, setGenerated, idx])

    useEffect(() => {
        const inter = setInterval(() => {
            fetch()
        }, 10000)
        return () => clearInterval(inter)
    }, [fetch])

    useEffect(() => {
        fetch()
    }
        , [fetch])

    return useMemo(() => ({
        totalClaimed: claimed,
        dividendGenerated: generated,
        claimCount: claimCount
    }), [claimed, generated, claimCount])
}

export const useStakeNFT = () => {
    const { account } = useActiveWeb3React()
    const dispatch = useAppDispatch()

    const fetch = useCallback(async () => {
        if (account) {
            dispatch(fetchUserStakeNFT(account))
        }
    }, [account, dispatch])

    useEffect(() => {
        const inter = setInterval(() => {
            fetch()
        }, 10000)
        return () => clearInterval(inter)
    }, [fetch])

    useEffect(() => {
        fetch()
    }, [fetch])
}