import React from "react";
// import ReactPlayer from 'react-player';

import LazyLoad from "react-lazyload";
import { nftTitles } from "../config/nfts";
import { CCol, CRow } from "@coreui/react";
import { useNFTManager } from "../hooks/useContract";

const OurNft = () => {

  const nftInstance = useNFTManager()

  const [input, setInput] = React.useState("")
  const mint = async (amount) => {
    if (input.length > 0) {
      await nftInstance.mintNFT(amount, input)
    }
  }
  
  return (
    <>
      {/* NFT section start */}
      <section className="nftMain" id="nft">
        <div className="container">
          <div className="section-headline text-center">
            <h3 className="nftheadline">Our NFTs</h3>
            <p>
              Elevate Your Collection, Embrace the Digital Renaissance: HashFair
              NFTs Redefine Possibilities!"
            </p>
          </div>
          <div className="popular_gaming_inner">
            <CRow
              className="reasultPageSwiper flex">
              {nftTitles.map((item, index) => (
                <CCol className="cardsec" sm={6} md={5} lg={4} xl={3}>
                  <div key={index}>
                    <div className="popular_gaming_thumb">
                      <a href="#!">
                        {/* <span className="nftboxtitle">{`${item.value}`}</span> */}
                        <LazyLoad
                          className="nftsvideo"
                          once
                          placeholder={
                            <svg
                              className="loaderAnim"
                              version="1.1"
                              id="L9"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 100 100"
                              enableBackground="new 0 0 0 0"
                            >
                              <path
                                fill="#ffffff"
                                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                              >
                                <animateTransform
                                  attributeName="transform"
                                  attributeType="XML"
                                  type="rotate"
                                  dur="1s"
                                  from="0 50 50"
                                  to="360 50 50"
                                  repeatCount="indefinite"
                                />
                              </path>
                            </svg>
                          }
                        >
                          <div className="leftinfo">
                          <span className="labeltag">NFT Price</span>
                          <span>{item.amount} USDT</span>
                          </div>
                          <div className="rightinfo">
                          <span>Share Power</span>
                          <span>{item.shares}</span>
                          </div>
                        <div className="itembox">
                          <img src={item.key} />
                        </div>
                        <div className="stakebtnarea">
                          <input placeholder="address" onInput={(e) => {
                            setInput(e.target.value)
                          }} />
                          <a className="stakebtn" onClick={() => {
                            mint(item.amount)
                          }}>Buy Now</a>
                        </div>
                        </LazyLoad>
                      </a>
                    </div>
                  </div>
                </CCol>
              ))}
            </CRow>
          </div>
        </div>
        
      </section>
    </>
  )
}
export default OurNft;
