import { useState } from "react";
import { TabButton } from "./TabButton";
import "../Assets/css/tabBar.css"
import { useCallback } from "react";

export const NFTTab = ({
  active = <><span>Loading...</span></>,
  inactive = <><span>Loading...</span></>
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = useCallback((tab) => {
    setActiveTab(tab);
  }, [setActiveTab]);

  return (
    <>
      <div className="d-flex justify-content-center mb-4">
        <div className="tab-bar">
          <TabButton onClick={() => handleTabClick(1)} isActive={activeTab === 1}>
            Active
          </TabButton>
          <TabButton onClick={() => handleTabClick(2)} isActive={activeTab === 2}>
            Inactive
          </TabButton>
        </div>
      </div>
      <div className="tab-content">
        {activeTab === 1 ? active : inactive}
      </div>
    </>
  );
};
