import { toast } from "react-toastify";

import Swal from 'sweetalert2/dist/sweetalert2.js'

export const PENDING_MESSAGE = 'Transaction Submitted...'
export const ERROR_MESSAGE = 'Error while handling Transaction!!'
export const SUCCESS_MESSAGE = 'Transaction Confirmed!!'

export const ERROR_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const SUCCESS_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const INFO_PROP = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const showError = (message) => Swal.fire({
    title: 'Unhandled Error',
    text: message,
    icon: 'error',
    confirmButtonText: 'Close'
})
export const showFulfill = (message, title = 'Transaction Submitted') => Swal.fire({
    title: title,
    text: message,
    icon: 'success',
    confirmButtonText: 'Close'
})

function someAsyncFunction() {
    return new Promise((resolve, reject) => {
        // Simulate an asynchronous operation
        setTimeout(() => {
            // Resolve the promise with some data
            resolve("Data successfully fetched!");
        }, 22000); // Simulating a 2-second delay
    });
}

export function handleAsyncOperation(txnObj) {
    return new Promise(async (res, rej) => {

        try {
            // Show a loading spinner while the promise is pending
            Swal.fire({
                title: "Confirming Transaction",
                html: `
                <div>
                <svg style='width: 100px' version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                        <path fill="#000" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite" />
                        </path>
                    </svg>
                    <p>
                        <a href="https://polygonscan.com/tx/${txnObj.hash}" target="_blank" rel="noreferrer">
                            View on Explorer
                        </a>
                    </p>
                </div>`, // Your loader HTML (you can use CSS to style it)
                showConfirmButton: false,
                allowOutsideClick: false
            });
    
            // Call the async function and wait for the result
            // await someAsyncFunction();
            await txnObj.wait()
            res(true)
        } catch (error) {
            // Close the loading spinner and show an error message
            Swal.fire({
                title: "Error!",
                text: `An error occurred while handling data. ${error.reason ? error.reason: (error.data ? error.data.message : error.message)}`,
                icon: "error",
            });

            res(false)
        }
    })
}

export const showPromise = (promise, pendingMessage = PENDING_MESSAGE, errorMessage = ERROR_MESSAGE, successMessage = SUCCESS_MESSAGE) => {
    return toast.promise(
        promise,
        {
            pending: pendingMessage,
            success: successMessage,
            error: errorMessage
        },
        SUCCESS_PROP
    )
}


