import React, { useCallback } from "react";
import {
  CButton,
  CHeader,
  CHeaderBrand,
  CContainer,
} from "@coreui/react";
import logo from "../Assets/images/logo.json";
import useActiveWeb3React from "../hooks/useWeb3";
import useAuth from "../hooks/useAuth";
import { ConnectorNames } from "../utils/web3React";
import { shortAddress } from "../utils";
import LazyLoad from "react-lazyload";
import Lottie from "react-lottie";
import marqueelogo1 from "../Assets/images/marqueelogo.webp"

const Header = () => {

  const { account } = useActiveWeb3React();

  const { login } = useAuth();

  const injectCall = useCallback(() => {
    login(ConnectorNames.Injected);
  }, [login]);

  // const walletConnectCall = useCallback(() => {
  //   login(ConnectorNames.WalletConnect)
  // }, [login])

  return (
    <>
        <div class="marquee">
          <marquee behavior="scroll" direction="Left">
            <span>
              {/* Mainnet Web3 Games are live. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={marqueelogo1}
                width={40}
                height={40}
                alt="Hash Fair Logo"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                
              
            
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Staking for HFG Coin is Now Live.
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={marqueelogo1}
                width={40}
                height={40}
                alt="Hash Fair Logo"
              />
            
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  HFG Withdrawals Are Now Live on HashFair Games.
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={marqueelogo1}
                width={40}
                height={40}
                alt="Hash Fair Logo"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hashfair NFT Marketplace is Live                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <img
                src={marqueelogo1}
                width={40}
                height={40}
                alt="Hash Fair Logo"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
              The distribution of HFG tokens has been temporarily paused due to an issue on the Polygon network. It is expected to resume on either April 29th or April 30th, 2024. Additionally, any pending distributions from previous days will be processed after the distribution resumes.
            </span>
          </marquee>
        </div>
      <header>
        <CHeader>
          <CContainer>
            <CHeaderBrand to="/">
              {" "}
              <div className="header_logo">
                <a className="sticky_none" href="#!!">
                  <LazyLoad
                    className="logoMainHead"
                    once
                    placeholder={
                      <svg
                        className="loaderAnim"
                        version="1.1"
                        id="L9"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 100 100"
                        enable-background="new 0 0 0 0"
                      >
                        <path
                          fill="#ffffff"
                          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        >
                          <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="rotate"
                            dur="1s"
                            from="0 50 50"
                            to="360 50 50"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    }
                  >
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: logo,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                    />
                  </LazyLoad>
                </a>
              </div>
            </CHeaderBrand>
            {!account && (
              <CButton className="btnMU" onClick={injectCall}>
                <span>Connect Wallet</span>
              </CButton>
            )}
            {account && (
              <CButton className="btnMU">
                <span>{shortAddress(account)}</span>
              </CButton>
            )}
          </CContainer>
        </CHeader>
      </header>
    </>
  );
};

export default Header;
