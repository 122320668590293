import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Outlet } from "react-router-dom";
import image1 from "../Assets/images/roulettewheelanim.webp"

const Layout = () => {
  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
           
      <div class="web3gamesfix">
        <a href="https://hashfair.games/" target="_blank" title="WEB3 GAMES">
            <img src={image1} width="50" height="50" alt="web3 games" />
        </a>
        </div>
    </>
  );
};

export default Layout;
