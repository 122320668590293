import { stakeContract } from "../../config"
import { getContract } from "../../utils/getContract"
import sftABI from '../../config/abis/IERC1155.json'

export const fetchAccountNFTBalance = async ({address, nftAddress, tokenID}) => {
    const nftInstance = getContract(nftAddress, sftABI)
    const nftBalance = await nftInstance.balanceOf(address, tokenID)
    return nftBalance.toNumber()
}

export const fetchAccountApprove = async ({address, nftAddress}) => {
    const nftInstance = getContract(nftAddress, sftABI)
    const nftApprove = await nftInstance.isApprovedForAll(address, stakeContract)
    return nftApprove
}
