import moment from "moment/moment"
import { useFetchClaims } from "../hooks/useFetchClaims"
import { EXPLORER_LINKS, feeStructure } from "../config"
import { toLocale } from "../utils"

export const ClaimCounts = ({ idx }) => {
    const { claims, loading } = useFetchClaims(idx)

    console.debug(claims)

    return (
        <>
            <div>
                {
                    loading && <h5 className="text-center">Loading...</h5>
                }
                {
                    claims.map((cl, index) => {
                        const x = cl.amount / 1e18
                        const b = feeStructure[(claims.length - (index + 1))]
                        const origAmount = x / (1 - (b / 100))

                        console.debug(cl.blockTimestamp)
                        // const origAmount = 
                        return (
                            // <div className="card bg-dark mb-2">
                            // {/* <div className="card-body bg-dark"> */}
                            <div className="historydetails">
                                <div className='historyitem'>Claim Count <span>#{claims.length - (index)}</span></div>
                                <div className='historyitem'>Amount <span>{toLocale(origAmount)} HFG</span></div>
                                <div className='historyitem'>Fees <span>{toLocale(origAmount - x)} HFG</span></div>
                                <div className='historyitem'>Claimed <span>{moment(new Date(cl.blockTimestamp * 1000)).format("DD MMM hh:mm a")}</span></div>
                                <div className='historyitem'>View <span>
                                    <a target="_blank" rel="noreferrer" href={`${EXPLORER_LINKS}/tx/${cl.transactionHash}`}>View Txn</a>
                                </span></div>
                            </div>
                            // </div>
                            // </div>
                        )
                    })
                }
            </div>
        </>
    )
}